import { integrationPlugin } from '~/helpers/integrationPlugin';
import { mapConfigToSetupObject } from '~/modules/core/helpers';

const moduleOptions = JSON.parse('{"availablePaymentMethods":["scheme","paypal","klarna","klarna_account","klarna_paynow","wechatpayQR","applepay","afterpaytouch"],"clientKey":"test_CQEZA2IY5JAXNLMFF73J4OT42UTHVU5B","environment":"test","methods":{"card":{"brands":["mc","visa","amex","maestro","discover","jcb","cartebancaire","bcmc"],"hasHolderName":true,"holderNameRequired":true}}}');
export default integrationPlugin((plugin) => {
  const settings = mapConfigToSetupObject({
    moduleOptions,
    app: plugin.app
  });

  plugin.integration.configure('adyen', settings);
});
